<template>
  <div class="home-warp">
    <div class="top-bar">
      <div class="sub-left" @click="jumpTo">
        <div style="width: 0.75rem;height: 0.75rem;">
          <img class="home-b-t" src="../../static/images/home-b-t.png" alt="" />
        </div>
        <div style="width: 1.47rem;height: 0.49rem;margin-top: -0.6rem;margin-left: 0.7rem;">
          <img class="home-b-a" src="../../static/images/home-b-a.png" alt="" />
        </div>
      </div>
      <div class="sub-right" @click="jumpToHome">
        <img class="home-s-b" src="../../static/images/close.png" alt="" />
      </div>
    </div>
    <div class="con-bar">
      <div class="item-box plr5" @click="isShow1 = !isShow1">
        <span class="bold" :class="{'font-active': !isShow1}">{{$t('m.home.nav_1')}}</span>
        <!-- <span class="bold" :class="{'font-active': !isShow1}">简介</span> -->
        <img v-show="isShow1" class="arrow-right" src="../../static/images/arrow-right.png" alt="">
        <img v-show="!isShow1" class="arrow-down" src="../../static/images/arrow-down.png" alt="">
      </div>
      <div v-show="!isShow1" class="item-box2">
        <p @click="jumpToCompany">{{$t('m.home.nav_1_sub_1')}}</p>
        <p @click="jumpToAwards">{{$t('m.home.nav_1_sub_2')}}</p>
      </div>
      <div class="item-box plr5" @click="isShow2 = !isShow2">
        <span class="bold" :class="{'font-active': !isShow2}">{{$t('m.home.nav_2')}}</span>
        <img v-show="isShow2" class="arrow-right" src="../../static/images/arrow-right.png" alt="">
        <img v-show="!isShow2" class="arrow-down" src="../../static/images/arrow-down.png" alt="">
      </div>
      <div v-show="!isShow2" class="item-box2">
        <p @click="jumpToGovernment">{{$t('m.home.nav_2_sub_1')}}</p>
        <p @click="jumpToUser">{{$t('m.home.nav_2_sub_2')}}</p>
      </div>
      <div class="item-box plr5" @click="isShow3 = !isShow3">
        <span class="bold" :class="{'font-active': !isShow3}">{{$t('m.home.nav_3')}}</span>
        <img v-show="isShow3" class="arrow-right" src="../../static/images/arrow-right.png" alt="">
        <img v-show="!isShow3" class="arrow-down" src="../../static/images/arrow-down.png" alt="">
      </div>
      <div v-show="!isShow3" class="item-box2">
        <p @click="jumpToMedia">{{$t('m.home.nav_3_sub_1')}}</p>
        <p @click="jumpToMedia">{{$t('m.home.nav_3_sub_2')}}</p>
        <p @click="jumpToMedia">{{$t('m.home.nav_3_sub_3')}}</p>
      </div>
      <div class="item-box plr5" @click="jumpToPartners">
        <span class="bold">{{$t('m.home.nav_4')}}</span>
      </div>
      <div class="item-box plr5" @click="jumpToContactUs">
        <span class="bold">{{$t('m.home.nav_5')}}</span>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="word-box">
        <span :class="{'border-active': borderActive}" @click="changeZH">中文</span>
        <span :class="{'border-active': !borderActive}" @click="changeEN">English</span>
      </div>
      <div style="margin-top:0.3rem;">
        <p style="color: #FFAB0A;font-size: .48rem;display: flex;align-items: center;justify-content: center;">
          <img style="width:0.59rem;height:0.59rem;object-fit:cover;margin-right:0.1rem;" src="../../static/images/phone.png" alt="">
          <a class="tell-a" href="tel:400-0428-468" style="color: #FFAB0A;">400-0428-468</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home2",
  data() {
    return {
      isShow1: true,
      isShow2: true,
      isShow3: true,
      borderActive: true,
    }
  },
  created() {
    this.borderActive = JSON.parse(localStorage.getItem('borderActive')) === null ? true :  JSON.parse(localStorage.getItem('borderActive'))
    this.borderActive ? this.$i18n.locale = 'zh-CN' : this.$i18n.locale = 'en-US'
  },
  methods: {
    jumpTo() {
      this.$router.push('/')
    },
    jumpToHome() {
      this.$router.go(-1)
    },
    jumpToCompany() {
      this.$router.push('/synopsis')
    },
    jumpToAwards() {
      this.$router.push('/awards')
    },
    jumpToGovernment() {
      this.$router.push('/faceGovernment')
    },
    jumpToUser() {
      this.$router.push('/faceUser')
    },
    jumpToMedia() {
      this.$router.push('/media')
    },
    jumpToPartners() {
      this.$router.push('/partners')
    },
    jumpToContactUs() {
      this.$router.push('/contactUs')
    },
    changeZH() {
      this.borderActive = true
      this.$i18n.locale = 'zh-CN'
      // 保存到本地
      localStorage.setItem('borderActive', JSON.stringify(this.borderActive))
    },
    changeEN() {
      this.borderActive = false
      this.$i18n.locale = 'en-US'
      localStorage.setItem('borderActive', JSON.stringify(this.borderActive))
    }
  }
}
</script>

<style lang="scss" scoped>
.home-warp{
  background: #F9F9F9;
}
.home-title {
  width: 10rem;
  height: 7.07rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    outline: none;
  }
  .title-sub {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 9.31rem;
    box-sizing: border-box;
    margin-top: 0.33rem;
    margin-right: 0.41rem;
    margin-left: 0.29rem;
    .sub-left {
      .home-b-t {
        width: 0.75rem;
        height: 0.75rem;
        object-fit: cover;
      }
      .home-b-a {
        width: 1.47rem;
        height: 0.49rem;
        object-fit: cover;
        vertical-align: text-top;
        margin-left: 0.1rem;
      }
    }
    .sub-right {
      .home-s-b {
        width: 0.68rem;
        height: 0.57rem;
        object-fit: cover;
      }
    }
  }
}
.top-bar{
  height: 1.57rem;
  width: 100%;
  background: #FFAB0A;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .sub-left {
    padding-left: .48rem;
    .home-b-t {
      width: 0.75rem;
      height: 0.75rem;
      object-fit: cover;
    }
    .home-b-a {
      width: 1.47rem;
      height: 0.49rem;
      object-fit: cover;
      vertical-align: text-top;
      margin-left: 0.1rem;
    }
  }
  .sub-right {
    padding-right: .48rem;
    .home-s-b {
      width: 0.68rem;
      height: 0.57rem;
      object-fit: cover;
    }
  }
}
.con-bar{
  background: #ffffff;
  color: #666;
  font-size: .4rem;
}
.plr5{
  padding: 0 .5rem;
}
.item-box{
  height: 1.36rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.item-box::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #CACACA;
  transform: scaleY(0.5);
}
.arrow-right{
  width: .4rem;
}
.arrow-down{
  width: .45rem;
}
.bold{
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.font-active{
  color:#FFAB0A;
}
.item-box2{
  padding: 0.2rem .92rem;
  color: #999;
  font-size: 0.36rem;
  border-bottom: 1px solid #CACACA;
  p{
    line-height: 1rem;
    height: 1rem;
  }
}
.bottom-bar {
  background: #fff;
}
.word-box{
  color: #666;
  font-size: .41rem;
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: space-around;
  span{
    width: 1.8rem;
    height: .68rem;
    line-height: .68rem;
    border: 1px solid #CACACA;
    text-align: center;
  }
}
.border-active{
  border: 1px solid #FFAB0A !important;
}
.tell-a{
  text-decoration:none;
  font-weight: 400;
}
</style>
